<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          ถอนเงิน
        </h3>
      </div>

      <div class="card-list p-puple mt-1">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <img
              src="/₩.png"
              height="55"
            >

            <div class="ml-1">
              <p class="mb-25 text-white">
                ยอดเงินคงเหลือ
              </p>

              <h3 class="text-white mb-0 font-large-2 cx mt-1">
                {{ Commas(balance) }}
                <small class="txt-warn text-white">₩</small>
              </h3>

              <small class="mb-50 text-white">
                {{ balance_datecheck ? balance_datecheck : "-" }}
              </small>
            </div>
          </div>

          <i
            class="fas fa-redo-alt text-white txp cursor-pointer"
            @click="getBalance"
          />
        </div>
      </div>

      <div class="mt-2">
        <h3 class="text mb-0">
          บัญชีของฉัน
        </h3>
        <validation-observer ref="stepFinish">
          <table class="w-100">
            <tbody>
              <tr>
                <td class="pt-50 pb-50">
                  <p class="mb-0 text-primary">
                    ธนาคาร
                  </p>
                </td>
                <td class="pt-50 pb-50">
                  <!-- <div class="d-flex align-items-center">
                  <img
                    :src="`/bank/${userData.bank}.jpg`"
                    alt="bank-img"
                    height="30"
                  >

                  <p class="ml-50 mb-0 text-primary ">
                    {{ userData.bank_name }}
                  </p>
                </div> -->
                  <div
                    class="box-bank"
                    @click="showBank()"
                  >
                    <div
                      v-if="bank.code > 0"
                      class="img-bank"
                    >
                      <img
                        :src="`/bank/${
                          bank.code ? `${bank.code}.jpg` : 'bank.png'
                        }`"
                        :alt="`${bank.code}`"
                        height="25"
                      >
                    </div>

                    <div
                      class="detail-bank"
                      :class="bank.code ? 'ml-1' : ''"
                    >
                      <!-- <p class="mb-0"> -->
                      {{ bank.name ? bank.name : "เลือกธนาคาร" }}
                      <!-- </p> -->
                    </div>

                    <div
                      v-if="bank.code"
                      class="icon-bank"
                    >
                      <i class="fas fa-shield-check text-success" />
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="pt-50 pb-50">
                  <p class="mb-0 text-primary">
                    เลขที่บัญชี
                  </p>
                </td>
                <td class="pt-50 pb-50">
                  <validation-provider
                    #default="{ errors }"
                    name="Bank Number"
                    rules="required"
                  >
                    <b-form-input
                      v-model="bankNumber"
                      class="form-valid"
                      :state="errors.length > 0 ? false : null"
                      :disabled="!bank.code"
                      :placeholder="
                        bank.code ? 'กรุณากรอกเลขบัญชี' : 'กรุณาเลือกธนาคาร'
                      "
                      type="number"
                      @keyup="CheckLength(bankNumber)"
                    />
                    <small class="text-danger">{{
                      errors[0] ? "กรุณากรอกเลขบัญชีให้ถูกต้อง" : ""
                    }}</small>
                  </validation-provider>
                </td>
              </tr>

              <!-- <tr>
                <td class="pt-50 pb-50">
                  <p class="mb-0 text-primary ">
                    ชื่อบัญชี
                  </p>
                </td>
                <td class="pt-50 pb-50">
                  <p class="mb-0 text-primary ">
                    {{ userData.acc_name }}
                  </p>
                </td>
              </tr> -->
            </tbody>
          </table>

          <div class="input-box-group mt-1">
            <img
              src="/₩.png"
              height="35"
            >
            <validation-provider
              #default="{ errors }"
              name="Balance Withdraw"
              rules="required"
            >
              <input
                v-model="balance_withdraw"
                type="number"
                :state="errors.length > 0 ? false : null"
                class="form-withdraw w-100"
                placeholder="ระบุจำนวนเงิน"
              >
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </validation-observer>
        <div class="mt-1 text-center">
          <button
            class="btn-gray-txt p-75 w-50"
            @click="submit"
          >
            ถอนเงิน
          </button>
        </div>
      </div>
    </div>
    <!-- Modal Bank-->
    <b-modal
      ref="my-modal"
      hide-footer
      hide-header
      centered
    >
      <div class="row mt-1 mb-2">
        <div class="col-12 text-center mb-1">
          <h5 class="mb-0 text-primary">
            โปรดเลือกธนาคาร
          </h5>
        </div>
        <!-- {{ items }} -->
        <div
          v-for="item in items"
          :key="item.id"
          class="col-md-2 col-3 p-0 gtx"
        >
          <div
            class="card-bank-list"
            @click="selectBank(item.id)"
          >
            <img
              :src="`/bank/${item.code}.jpg`"
              :alt="item.code"
              width="100%"
              :class="{ active: isActive === item.id }"
              :style="{ opacity: getOpacity(item) }"
            >
          </div>
        </div>
      </div>
    </b-modal>

    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BModal, BFormInput } from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
// import ThisHeader from './components/ThisHeader.vue'
import { required, email, confirmed } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ThisFooter from './components/ThisFooter.vue'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    ThisFooter,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      confirmed,
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
      balance: localStorage.getItem('balance'),
      balance_withdraw: 0,
      balance_datecheck: '',
      Loadmore: false,
      bank: [],
      items: [],
      bankNumber: null,
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => item.status === 1)
    },
  },
  created() {
    this.$http.get('/lotto-suvarn/banklist/korean').then(res => {
      this.items = res.data.filter(item => item.status === 1)
    })
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    if (this.userData) {
      this.getBalance()
    }
  },
  methods: {
    CheckLength(Val) {
      if (Val.length > 14) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'กรุณากรอกเลขบัญชีไม่เกิน 14 หลัก',
            variant: 'danger',
            icon: 'BellIcon',
          },
        })
        this.bankNumber = Val.slice(0, 14)
      }
    },
    showBank() {
      this.$refs['my-modal'].show()
    },
    selectBank(id) {
      this.isActive = id
      this.bank = this.items.find(item => item.id === id)

      setTimeout(() => {
        this.$refs['my-modal'].hide()
      }, 500)
    },
    getOpacity(item) {
      return this.isActive === null || this.isActive === item.id ? '1' : '0.2'
    },
    submit() {
      this.$refs.stepFinish.validate().then(success => {
        if (success) {
          this.Loadmore = true
          if (!this.balance_withdraw || Number(this.balance_withdraw) <= 0) {
            this.SwalError('กรุณากรอกจำนวนเงิน')
            this.Loadmore = false
            return
          }
          if (Number(this.balance_withdraw) <= Number(this.balance)) {
            const params = {
              username: this.userData.username,
              amount: this.balance_withdraw,
              uid: this.userData.id,
              bank: this.bank,
              acc_no: this.bankNumber,
            }
            // console.log(params)
            this.$http
              .post('/withdraw/store', params)
              .then(response => {
                this.Loadmore = false
                this.balance_withdraw = 0
                this.Success(response.data.message)
                this.getBalance()
              })
              .catch(error => {
                this.Loadmore = false
                this.SwalError(error.response.data.message)
              })
          } else {
            this.Loadmore = false
            this.SwalError('ยอดเครดิต ไม่เพียงพอสำหรับการถอน')
          }
        }
      })
    },
    getBalance() {
      this.$http.get('balance/getbalance').then(ress => {
        // console.log(ress.data)
        this.balance = ress.data.balance
        this.balance_datecheck = moment()
          .tz('Asia/Bangkok')
          .format('YYYY-MM-DD HH:mm:ss')
        localStorage.setItem('balance', JSON.stringify(ress.data.balance))
      })
    },
    Commas(x) {
      if (!x) {
        return 0
      }
      if (x % 1 !== 0) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ลงตะกร้าสำเร็จ</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped></style>
